<template>
  <div class="legacy-password-reset">
    <div class="card overflow-hidden">
      <div class="bg-soft-primary">
        <div class="row">
          <div class="col-12">
            <div class="p-4">
              <h5 class="text-black">Welcome to the new Account Portal!</h5>
              <p>
                To start using the new portal, please enter your old password
                and setup a new one. Your new password must be a minimum of 8
                characters, include at-least one number and one uppercase
                character
              </p>

              <p class="text-hint">
                If you don't recall your old password, or have difficulty
                setting a new one, Please click
                <router-link
                  tag="a"
                  :to="{ name: 'ForgotPassword' }"
                  class="font-weight-medium text-primary"
                  >here</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-3">
        <error :err.sync="error" />

        <!-- <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >{{notification.message}}</div> -->

        <b-form class="p-2" @submit.prevent="reset">
          <b-form-group
            id="input-group-1"
            :label="$t('current-password')"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="oldPassword"
              type="password"
              :placeholder="$t('current-password')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-1"
            :label="$t('new-password')"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="newPassword"
              type="password"
              :placeholder="$t('new-password')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-1"
            :label="$t('confirm-password')"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="confirmPassword"
              type="password"
              :placeholder="$t('confirm-password')"
            ></b-form-input>
          </b-form-group>

          <div class="mt-3">
            <ebp-button :loading="loading" type="submit" variant="primary">{{
              $t("reset-password")
            }}</ebp-button>
            <ebp-button
              type="button"
              @click="discard()"
              variant="danger"
              class="ml-2"
              >Cancel</ebp-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "legacy-password-reset",
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      error: null,
      loading: false
    };
  },
  mounted() {
    const user = this.$store.getters["auth/user"];

    if (!user.legacy_password) {
      this.$router.push({ name: "ClientDashboard" });
    }
  },
  methods: {
    async reset() {
      this.error = null;
      const { oldPassword, newPassword, confirmPassword } = this;
      if (!oldPassword || !newPassword || !confirmPassword) {
        this.error = this.$t("validation-errors.required-all");
      } else if (newPassword !== confirmPassword) {
        this.error = this.$t("validation-errors.passwords-dont-match");
      } else if (newPassword.length < 8) {
        this.error = "The new password must be at-least 8 characters long";
      } else if (!/\d/.test(newPassword)) {
        this.error = "The new password must contain at-least one numeric digit";
      } else if (!/[A-Z]/.test(newPassword)) {
        this.error =
          "The new password must contain at-least one uppercase character";
      } else {
        this.loading = true;

        try {
          const res = await this.$store.dispatch("users/resetLegacyPassword", {
            newPassword,
            oldPassword
          });
          this.$store.commit("auth/setUserData", {
            user: res.data.user,
            token: res.data.token
          });
          this.$router.push({ name: "ClientDashboard" });
        } catch (err) {
          console.error(err);
        }

        this.loading = false;
      }
    },

    async discard() {
      await this.$store.dispatch("auth/logout");
      this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style></style>
